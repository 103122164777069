import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { imageUrl } from "../../.env";
import "./header.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../PageLoader/PageLoader";
import {
  ArrowDownward,
  ArrowDownwardSharp,
  ArrowDropDown,
  ArrowDropDownSharp,
  Cancel,
  Close,
  Fitbit,
  KeyboardArrowDown,
  Menu,
  MenuOpen,
  Search,
  SearchSharp,
} from "@mui/icons-material";

var show = "none";
function Header(props) {
  const refMenu = useRef();
  const [menu_status,setMenuStatus]=useState('flex');
  const width = window.innerWidth;
  const showMenu=(fem)=>{
    setMenuStatus(fem);
    document.getElementById("menuContainer").style.display = menu_status;
    document.getElementById("mobileMenu").style.display = menu_status;
    // document.getElementById("mobileMenu").style.backgroundColor ='white';
}
  return (
    <nav>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="http://apexlonacktechnology.com.ng/" />
        <title>{props?.title} | ALT</title>
        <meta name="author" content="Apex Lonack Technology"></meta>
        <link rel="icon" href="./logo.png" type="image/ico"></link>
        <meta name="description" content="" />
      </Helmet>
      <div className="headRow">
        <div className="logoContainer" contentEditable="false">
          <img src="./logo.png" />
          <div className="columnlogo">
            <Link to={"/"} className="home">
              <h3>ALT</h3>
            </Link>
            <span>Apex Lonack Technology</span>
          </div>
        </div>
        <div id="menuContainer" className="menuContainer">
          <ul id="mobileMenu" className="listContainer">
            <Link className={`menu`}>HOME</Link>
            <Link className={`menu`}>PRODUCT</Link>
            <Link className={`menu`}>SERVICES</Link>
            <Link className={`menu`}>ABOUT</Link>
            <Link className={`menu`}>CONTACT</Link>
          </ul>
          <button>Login</button>
        </div>

        {menu_status==='flex'&&
          <button onClick={()=>showMenu('none')} className='menuSpan'>
          <Menu fontSize="large" />
          </button>}
          {menu_status==='none'&&
          <button onClick={()=>showMenu('flex')} className='menuSpan'>
          <Close fontSize="large" /> 
          </button>}
      </div>

      <PageLoader open={props.loader} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </nav>
  );
}

export default Header;
