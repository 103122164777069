import React from "react";
import "./rating.scss";
import Accordion from "../Accordion/Accordion";

function Rating(props) {
  return (
    <div className="rating" contenteditable="false">
      <div className="itemContainer">
        {/* <img style={{borderRadius: 10,}} src="./assets/testimony/workstation.jpeg" loading="lazy" /> */}
        <div className="item">
          <h2>
          Apex Lonack Technology is recognized for the highest quality and unfailing security of provided solutions.
          </h2>
          <div className="accordion">
                <Accordion title="Service Quality" content={"We’ve been elaborating our quality assurance processes."} />
                <Accordion title="Information security" content={"We safeguard the information assets that we access during the project."} />
                <Accordion title="Dedication to innovations" content={"Our ability to provide innovative transformative solutions."} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rating;
