import React from "react";
import "./subscribe.scss";

function Subscribe(props) {
  return (
    <div className="subscribe">
      <div className="container">
        <div className="ellipse"></div>
        <div className="form">
          <h4>An enterprise template to ramp up your company website</h4>
          <div className="control">
            <input placeholder="Your email address" className="input" type="text"  />
            <button>Start Now</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
