import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
// import { baseUrl } from '../../env';
import "../../App.css";
import "./home.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Slide from "../../components/Slide/Slide";
import Hero from "../../components/Hero/Hero";
import Partner from "../../components/Partner/Partner";
import Advert from "../../components/Advert/Advert";
import Service from "../../components/Service/Service";
import Rating from "../../components/Rating/Rating";
import Notify from "../../components/Notification/Notify";
import { baseUrl } from "../../.env";
import { _retrieveData, _storeData } from "../../Service/function";
import Popup from "../../components/Popup/Popup";
import { Helmet } from "react-helmet";
import Testimony from "../../components/Testimony/Testimony";
import Blog from "../../components/Blog/Blog";
import Subscribe from "../../components/Subscribe/Subscribe";

const Home = () => {
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const homeRef = useRef();

  const retrieveData = async () => {
    try {
      const data = await _retrieveData("api");
      if (data) {
        setData(data);
      } else {
        setLoader(true);
      }
      const res = await axios.get(baseUrl + "guest/home");
      _storeData(res.data, "api");
      setData(res.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // retrieveData();
  }, []);

  return (
    <div className="home_container">
      <main className="content_container">
        <section className="top_content">
          <div className="ellipse_sect"></div>
          <Header
            banner={data?.data?.banner}
            loader={loader}
            title="Homes"
            active={`home-`}
            setting={data?.data?.setting}
            menu={data?.data?.menu}
          />
        </section>
        <section className="body_content">
          <Slide />
          <Partner />
          <Service />
          <Advert />
          <Hero />
          <Testimony />
          <Rating />
          {/* <Blog /> */}
          {/* <Subscribe /> */}
          <Footer />
        </section>
       
      </main>
    </div>
  );
};

export default Home;
