import React from 'react';
import { imageUrl } from '../../.env';
import "./partner.scss";

function Partner(props) {
    return (
        <div className='partner' contenteditable="false">
        <h2>Our Clients</h2>
            <div className='imageContainer'>
                <img loading="lazy" style={{width:150,objectFit:'contain',background:'white'}} src={`https://pmbl-ng.com/uploads/setting/1557609466.png`}  />
                <img loading="lazy" style={{width:150,objectFit:'contain'}} src={`https://niss.gov.ng/wp-content/uploads/2019/02/cropped-niss-logo.jpg`}  />
                <img loading="lazy" style={{width:150,objectFit:'contain'}} src={`https://learnwithlakeshore.com.ng/setting/small/167752435632.PNG`}  />
                <img loading="lazy" style={{width:150,objectFit:'contain'}} src={`https://www.lakeshorecancercenter.org/wp-content/uploads/2019/09/LakeshoreCancerCenterlogo2.png`}  />
                <img loading="lazy" style={{width:150,objectFit:'contain'}} src={`https://www.ocpafrica.com/sites/default/files/Logo%20OCP%20AFRICA%20couleurs.png`}  />
            </div>
        </div>
    );
}

export default Partner;