import React, { useState } from "react";
import "./slide.scss";
import {
  Circle,
  Facebook,
  Instagram,
  LinkedIn,
  Phone,
  YouTube,
} from "@mui/icons-material";
import { imageUrl } from "../../.env";
import SliderContent from "./SliderContent";
import { Link } from "react-router-dom";

function Slider(props) {
  const getWidth = () => window.innerWidth;
  const [current, setCurrent] = useState(0);
  const [ticker, setTick] = useState(0);

  React.useEffect(() => {
    var timerID = setInterval(() => tick(), 10000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });
  const countSLide = props.data ? props.data.item?.length : 0;
  function tick() {
    if (current < countSLide - 1 && ticker == 0) {
      setCurrent(current + 1);
      setTick(0);
    } else if (current == countSLide - 1) {
      setTick(1);
    }
  }

  return (
    <section className="sectionContainer" contenteditable="false">
      <div className="textContainer">
        <div className="textBox">
          {/* <p>Well known development company</p> */}
          <h2>Let's Support Your Business Growth Or Monetize Your Idea?</h2>
          <p>We transform businesses with powerful and adaptable digital solutions that satisfy the needs of today and unlock the opportunities of tomorrow</p>
        </div>
        <div className="actionContainer">
          <a href="mailto:apexlonacktechnology@gmailcom" className="m-primary">
            GET OUR QUOTE
          </a>
        </div>
      </div>
      <div className="imageContainer">
        <div className={`slideItem `} key={0}>
          <img loading="lazy" src={`./back/hero.png`} />
        </div>
        {/* <div className='slideControl'>
            {props.data?.item.map((slide,index)=>(
                <a onClick={()=>setCurrent(index)} className={`${current==index?'dot':''}`}></a>
            ))}
            </div> */}
      </div>
    </section>
  );
}

export default Slider;
