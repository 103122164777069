import { ArrowCircleRightOutlined } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import { imageUrl } from "../../.env";
import "./service.scss";

function Service(props) {
  return (
    <div className="service" contenteditable="false">
      <div className="headingContainer">
        <h1>Our services</h1>
        <div className="subHeader">
          <span>
            We optimize IT operations to increase their value for business
          </span>
        </div>
      </div>
      <div className="rowService">
        <div className="itemContainer">
                <img src="./back/service_in.png" loading="lazy" />
                <div className="subTitle">
                    <h2>IT Consulting</h2>
                    <Link to={'/'}><ArrowCircleRightOutlined fontSize='large' color='primary' /></Link>
                </div>
        </div>
        <div className="itemContainer">
                <img src="./back/service_out.png" loading="lazy" />
                <div className="subTitle">
                    <h2>IT outsourcing</h2>
                    <Link to={'/'}><ArrowCircleRightOutlined fontSize='large' color='primary' /></Link>
                </div>
        </div>
        <div className="itemContainer">
                <img src="./back/service_job.png" loading="lazy" />
                <div className="subTitle">
                    <h2>Business automation</h2>
                    <Link to={'/'}><ArrowCircleRightOutlined fontSize='large' color='primary' /></Link>
                </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
