import React from 'react';
import "./pageloader.scss";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Audio, RotatingTriangles } from 'react-loader-spinner'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height:'100%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
  };
function PageLoader(props) {
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <Box sx={style}>
            <div className='loadContainer' >
            <RotatingTriangles
            colors={['#154ebe', '#f9ff02', '#154ebe']}
                visible={true}
                height="150"
                width="150"
                ariaLabel="rotating-triangels-loading"
                wrapperStyle={{}}
                 wrapperClass="rotating-triangels-wrapper"
                />
            </div>
            
          </Box>  
        </Modal>
    );
}

export default PageLoader;