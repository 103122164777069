import {
    ArrowCircleLeft,
    ArrowCircleRight,
    ArrowLeft,
    ArrowLeftOutlined,
    ArrowRightAlt,
    ArrowRightOutlined,
    CheckCircle,
    HistoryEdu,
    HistoryToggleOff,
    ManageAccounts,
  } from "@mui/icons-material";
  import React, { useState } from "react";
  import { Link } from "react-router-dom";
  import { imageUrl } from "../../.env";
  import "./hero.scss";
  
  function Hero(props) {
    const [current, setCurrent] = useState(0);
    const previous = (e) => {
      if (current != 0) {
        setCurrent(current - e);
      }
    };
    return (
      <div className="hero" contentEditable="false">
        <div className={`advertItemContainer`}>
          <div className="descriptionContainer">
            <div className="headingContainer">
              <h1>
              Our approach to IT solutions has been a major confidence booster for our customers around the world
              </h1>
              <div className="itemAds">
              <HistoryEdu fontSize="large" />
                <span>
                Effective collaboration
                </span>
              </div>
              <div className="itemAds">
                <HistoryToggleOff fontSize="large" />
                <span>On-time project delivery</span>
              </div>
              <div className="itemAds">
                <ManageAccounts fontSize="large" />
                <span>
                    Self-management
                </span>
              </div>
            </div>
          </div>
          <div className="imageItem">
            <div
              className="background_image"
              style={{ backgroundImage: `url("./back/group_saff2.png")` }}
            >
              <img
                className="image_card"
                loading="lazy"
                src={`./back/saff2.png`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Hero;
  