import React from "react";
import { Link } from "react-router-dom";
import "./testimony.scss";

function Testimony(props) {
  return (
    <div className="testimony">
      <div className="headingContainer">
        <div className="subHeader">
          <span>What our clients says</span>
        </div>
      </div>
      <div className="rowService">
        <div className="itemContainer">
          <div className="subTitle">
            <em>
              "We appreciate their proactive approach and ability to suggest
              improvements to a prospective solution on both architectural and
              business levels"
            </em>
            <div className="bottom_comment">
              <img
              style={{height:100,objectFit:'contain'}}
                src="/assets/testimony/victor.jpeg"
                loading="lazy"
              />
              <div className="label_container">
                <span className="name_comment">Prof Victor Chude</span>
                <span className="describe_comment">
                  Registrar/CEO Nigeria Institute of Soil Science
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="itemContainer">
          <div className="subTitle">
            <em>
              "They have always been able to guide us and manage projects,
              delivering on time and on budget"
            </em>
            <div className="bottom_comment">
              <img
              style={{height:100,objectFit:'contain'}}
                src="/assets/testimony/johnson.jpeg"
                loading="lazy"
              />
              <div className="label_container">
                <span className="name_comment">Johnson Samuel</span>
                <span className="describe_comment">
                  AGM, IT/SYSTEMS Platinum Mortgage Bank
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="itemContainer">
          <div className="subTitle">
            <em>
              "We are fully satisfied with the work completed by Apex Lonack
              Technology. They do the job, and they do it well."
            </em>
            <div className="bottom_comment">
              <img
              style={{height:100,objectFit:'contain'}}
                src="/assets/testimony/emeka.jpeg"
                loading="lazy"
              />
              <div className="label_container">
                <span className="name_comment">Dr. Emeka C. Anene</span>
                <span className="describe_comment">
                  MD/CEO Jikwoyi Medical Center Abuja, Nigeria
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimony;
