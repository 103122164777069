import { ArrowDownward, ArrowUpward, ExpandCircleDown } from "@mui/icons-material"
import React, { useState, useRef } from "react"


const Accordion = ({ title, content }) => {
  const [isOpened, setOpened] = useState(false)
  const [height, setHeight] = useState("0px")
  const contentElement = useRef(null)

  const HandleOpening = () => {
    setOpened(!isOpened)
    setHeight(!isOpened ? `${contentElement.current.scrollHeight}px` : "0px")
  }
  return (
    <div onClick={HandleOpening} className="shadow border border-x-0 border-t-0 border-gray-400">
      <div className={"bg-white-300 p-4 flex justify-between text-blue-600"}>
        <h4 className="text-sm text-black ">{title}</h4>
        {isOpened ? <ExpandCircleDown color="gray" /> : <ExpandCircleDown color="gray" />}
      </div>
      <div
        ref={contentElement}
        style={{ height: height }}
        className="bg-gray-1000 overflow-hidden transition-all duration-200"
      >
        <p className="text-sm text-gray-600 p-4">{content}</p>
      </div>
    </div>
  )
}

export default Accordion
