import {
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowLeft,
  ArrowLeftOutlined,
  ArrowRightAlt,
  ArrowRightOutlined,
  CheckCircle,
} from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { imageUrl } from "../../.env";
import "./advert.scss";

function Advert(props) {
  const [current, setCurrent] = useState(0);
  const previous = (e) => {
    if (current != 0) {
      setCurrent(current - e);
    }
  };
  return (
    <div className="advert" contentEditable="false">
      <div className={`advertItemContainer`}>
        <div className="imageItem">
          <div
            className="background_image"
            style={{ backgroundImage: `url("./back/group_saff.png")` }}
          >
            <img
              className="image_card"
              loading="lazy"
              src={`./back/saff.png`}
            />
          </div>
        </div>
        <div className="descriptionContainer">
          <div className="headingContainer">
            <h1>
              We connect our customers with the best, and help them keep up-and
              stay open.
            </h1>
            <div className="itemAds">
              <CheckCircle fontSize="large" />
              <span>
                We optimize IT operations to increase their value for business.
              </span>
            </div>
            <div className="itemAds">
              <CheckCircle fontSize="large" />
              <span>We cover 30-100% of your IT operations.</span>
            </div>
            <div className="itemAds">
              <CheckCircle fontSize="large" />
              <span>
                We implement and launch cost-effective and efficient IT
                solutions
              </span>
            </div>
            <a href="mailto:apexlonacktechnology@gmailcom">
              Start now <ArrowRightAlt />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advert;
